import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors, colorTransparency } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;

export const Heading = styled.p`
  ${font('text', 'sm', 700)}
  color: ${props =>
    props.theme === 'light' ? colors.gray[900] : colors.base.white};

  ${atMinWidth.sm`
    ${font('text', 'md', 700)}
  `}
`;

export const Content = styled.div`
  p, a{
    ${font('text', 'sm', 400)}
    color: ${props =>
      props.theme === 'light' ? colors.gray[900] : colors.gray[50]};

    ${atMinWidth.sm`
      ${font('text', 'sm', 400)}
    `}
  }

  a {
    font-weight: 700;
    transition: color 150ms ease-in-out;

    &:hover {
      text-decoration: none;
      color: ${props =>
        props.theme === 'light'
          ? colorTransparency(colors.gray[900], 0.8)
          : colorTransparency(colors.gray[50], 0.8)};
    }
  }
`;
